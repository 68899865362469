import PropTypes from 'prop-types';
import React from 'react';

import { ContentMatrix } from '../components/content-matrix';
import { fetchCMSContent } from '../utils/fetch-cms-content';
import { floodlightPageLoadEventPropTypes } from '../prop-types';
import { Layout } from '../components/layout';
import { seoPropDefaults, seoPropTypes } from '../utils/extract-seo-meta-from-entry';
import { withAppProps } from '../utils/with-app-props';

const HomePage = ({ title, contentMatrix, metaContent, floodlightPageLoadEvent }) => {
  return (
    <Layout title={title} metaContent={metaContent} floodlightPageLoadEvent={floodlightPageLoadEvent}>
      <ContentMatrix blocks={contentMatrix} pageTitle={title} />
    </Layout>
  );
};

HomePage.propTypes = {
  contentMatrix: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  floodlightPageLoadEvent: floodlightPageLoadEventPropTypes,
  metaContent: PropTypes.shape(seoPropTypes),
  title: PropTypes.string.isRequired,
};

HomePage.defaultProps = {
  floodlightPageLoadEvent: undefined,
  metaContent: seoPropDefaults,
};

export const getStaticProps = withAppProps(async () => {
  const data = await fetchCMSContent({
    uri: 'home',
    slug: 'home',
  });

  const page = data.id ? data : null;

  return {
    props: { ...page, generatedAt: new Date().toISOString() },
    revalidate: 60 * 2, // 2 minutes
  };
});

export default HomePage;
